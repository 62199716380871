import './style.scss';
import React, { useMemo, useEffect, useState } from 'react';
import MainHeader from '../../layout/common/Header/MainHeader';
import apiService from '@services/api.service';
import Sider from 'antd/es/layout/Sider';
import { Layout, Menu, theme } from 'antd';
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from "react-router";

const { Content } = Layout;

function MainLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  const { token: { colorBgContainer } } = theme.useToken();
  const [isLoading, setIsLoading] = useState(true);
  const [tabs, setTabs] = useState<string>('1');
  const [collapsed, setCollapsed] = useState(false);

  const navigateToLogin = () => {
    apiService.login().then((resp) => {
      if (resp?.data?.url) {
        window.open(resp?.data?.url, "_self");
      }
    }).catch((error) => {
      console.log('error: ', error);
    })
  }

  useEffect(() => {
    setTimeout(() => {
      const authToken = localStorage.getItem('auth');
      if (authToken) {
        setIsLoading(false);
      } else {
        navigateToLogin();
      }
    }, 1000);
  }, []);

  const items = useMemo(() => [
    {
      key: 1,
      label: "Practices",
      route: "/practices",
      icon: <UnlockOutlined rev={undefined} />
    },
    {
      key: 2,
      label: "Users",
      route: "/users",
      icon: <UnlockOutlined rev={undefined} />
    },
    {
      key: 3,
      label: "Mailjet",
      route: "/mailjet",
      icon: <MailOutlined rev={undefined} />
    },
    {
      key: 4,
      label: "Change Password",
      route: "/change-password",
      icon: <UnlockOutlined rev={undefined} />
    },
  ], []);

  useEffect(() => {
    const selectedItem = items.find((item) => item.route === location.pathname);
    setTabs(selectedItem?.key?.toString() as any);
  }, [location.pathname, items]);

  const onNavigateItemChanged = (e: any) => {
    const selectedItem = items[Number(e.key)-1];
    setTabs(e.key); 
    navigate(selectedItem.route);
  }

  return (
    <Layout>
      {
        !isLoading && <>
          <Sider collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <img className="demo-logo-vertical" style={{ height: '50px' }} src="https://images.squarespace-cdn.com/content/v1/642793d9ca3c4a75005acc38/be04dceb-1f22-4c2b-a2a6-d4befb173746/Header-Logo.png?format=1500w" alt="" />
            <Menu selectedKeys={[tabs]} onSelect={(e) => onNavigateItemChanged(e)} theme="dark" defaultSelectedKeys={[tabs]} mode="inline" items={items} />
          </Sider>

          <Layout>
            <MainHeader></MainHeader>
            <Content
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 'calc(100vh - 200px)',
                background: colorBgContainer,
              }}
            >
              <div className="main-container">
                <Outlet />
              </div>
            </Content>
          </Layout>
        </>
      }
      {
        isLoading && <div className="auth-container">
          <div className="auth-preloader">
            <svg
              viewBox="0 0 102 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="big-circle"
                d="M101 51C101 78.6142 78.6142 101 51 101C23.3858 101 1 78.6142 1 51"
                stroke="#252525"
                stroke-width="2"
              />
              <path
                className="small-circle"
                d="M91 51C91 28.9086 73.0914 11 51 11C28.9086 11 11 28.9086 11 51"
                stroke="#252525"
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      }
    </Layout>
  );
}

export default MainLayout;
