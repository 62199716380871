import './style.scss';
import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import ApiService from '@services/api.service';

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}

interface StatValueDisplayProps {
    value: number;
    animate?: boolean;
}


const StatValueDisplay = ({ value }: StatValueDisplayProps) => {
    return <span className={value === 0 ? 'red' : ''}>{value}</span>
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        width: 80,
        dataIndex: 'id',
    },
    {
        title: 'SID',
        width: 80,
        dataIndex: 'subscriptionId',
    },
    {
        title: 'Name',
        width: 240,
        render: (row) => (
            <div>
                <p style={{ margin: 0 }}>{row?.practiceName}</p>
            </div>
        ),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (value) => {
            if (value === 'Active') {
                return <Tag bordered={false} color="success">{value}</Tag>
            } else {
                return <Tag bordered={false} color="error">{value}</Tag>
            }
        },
    },
    {
        title: 'Department Id',
        render: (value) => <StatValueDisplay value={value?.absorbDepartmentId} />
    },
    {
        title: '# Active Licenses',
        render: (value) => <StatValueDisplay value={value?.learners?.length} />
    },
    // {
    //     title: 'Subscription Amount',
    //     render: (value) => {
    //         return `$${value?.subscriptions?.[0]?.amount}`
    //     }
    // },
];

function MedicalPractices() {
    const [searchResults, setSearchResults] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getActivePractices();
    }, []);

    function getActivePractices() {
        setIsLoading(true);

        ApiService.getPractices().then((response) => {
            if (response?.data) {
                console.log('response?.data: ', response?.data);
                setSearchResults(response.data);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
        });
    }

    return (
        <div>
            <div className="table-container">
                <Table
                    bordered
                    loading={isLoading}
                    columns={columns}
                    dataSource={searchResults}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 215px)', x: 100 }}
                />
            </div>
        </div>
    );
}

export default MedicalPractices;