/* eslint-disable */
import './style.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import ApiService from '@services/api.service';
import moment from 'moment';
import { cloneDeep, remove, uniqBy } from 'lodash';
import { toast } from 'react-toastify';

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}

function Users() {
    const [searchResults, setSearchResults] = useState<any>([]);
    const [tempSearchResults, setTempSearchResults] = useState<any>([]);
    const [medicalPractices, setMedicalPractices] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDropdownValue, setSelectDropdownValue] = useState<string>();
    const [usersColumns, setUsersColumns] = useState<ColumnsType<DataType>>();
    const [title, setTitle] = useState<string>();
    const [rerender, setRerender] = useState<boolean>(false);
    const [toggleAccessLoading, setToggleAccessLoading] = useState<null | number>(null);

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            width: 80,
            dataIndex: 'id',
        },
        {
            title: 'SID',
            width: 80,
            render: (row) => {
                return row?.medicalPractice?.subscriptionId
            }
        },
        {
            title: 'Name',
            render: (row) => (
                `${row?.name} ${row?.surname}`
            ),
        },
        {
            title: 'Email',
            render: (row) => (
                row?.email
            ),
        },
        {
            title: 'Practice Name',
            render: (row) => (
                row?.medicalPractice?.practiceName
            ),
        },
        {
            title: 'Role',
            render: (row) => (
                <div>
                    {row?.roleId === 1 && <Tag color="#CAE2CD" style={{ color: '#40916C' }}>Learner</Tag>}
                    {row?.roleId === 2 && <Tag color="#E4CFE1" style={{ color: '#84568E' }}>Leader</Tag>}
                    {row?.roleId === 3 && <Tag color="#2db7f5" style={{ color: '#000000' }}>Super Admin</Tag>}
                </div>
            ),
        },
        {
            title: 'Last Logged In',
            render: (row) => (
                row?.lastLoggedIn ? moment(row?.lastLoggedIn).startOf('hour').fromNow() : 'No Logins Yet'
            ),
        },
        {
            title: ' ',
            render: (row) => {
                return ToggleAccessBtn(row);
            }
        },
    ];

    useEffect(() => {
        setUsersColumns(columns);
    }, [columns]);

    const ToggleAccessBtn = (row: any) => { 
        return <Button loading={toggleAccessLoading === row.id} onClick={() => { doToggleAccess(row) }} style={{ color: row?.roleId === 1 ? '#84568E' : '#40916C' }} type="link">Assign as {row?.roleId === 1 ? 'Leader' : 'Learner'}</Button>
    };

    const doToggleAccess = (row: any) => {
        setToggleAccessLoading(row.id);
        ApiService.toggleAccess(row.id).then((response) => {
            toast.success("User role updated successfully", {
                position: "top-right",
            });
            setToggleAccessLoading(null);
            fetchUsers();
        }).catch((error) => {
            setToggleAccessLoading(null);
            console.log('error: ', error);
        });
    };

    const fetchActivePractices = useCallback(() => {
        setIsLoading(true);

        ApiService.getPractices().then((response) => {
            if (response?.data) {
                const practicesOptions = [
                    {
                        id: 'all',
                        practiceName: 'All Practices',
                    },
                    ...response.data
                ];

                setMedicalPractices(practicesOptions);
                setSelectDropdownValue('all');
                updateSectionTitle('all');
            };

            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
        });
    }, []);

    useEffect(() => {
        fetchUsers();
        fetchActivePractices();
    }, [fetchActivePractices]);

    function fetchUsers() {
        setIsLoading(true);

        ApiService.getProfiles().then((response) => {
            if (response?.data) {
                setSearchResults(response.data);
                setTempSearchResults(response.data);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
        });
    }

    const updateSectionTitle = (value: string) => {
        const selectedMedicalPractice = medicalPractices.find((practice: any) => practice.id === value);
        setTitle(selectedMedicalPractice?.practiceName);
    };

    const handleChange = (value: string) => {
        setSelectDropdownValue(value);
        updateSectionTitle(value);

        if (value === 'all') {
            setRerender(true);
            setSearchResults(tempSearchResults);
            setUsersColumns(columns);
        } else {
            const filteredData = tempSearchResults.filter((data: any) => data?.medicalPracticeId === value);
            setSearchResults(uniqBy(filteredData, 'id'));

            const newColumns = remove(cloneDeep(columns), (column: any) => column.title !== "Practice Name");
            setUsersColumns(newColumns);
        }

        setTimeout(() => {
            setRerender(false);
        }, 40);
    };

    return (
        <div className="users-container">
            <div className="section-header">
                <div className="title">
                    {searchResults?.length > 0 && <h2 className="section-title">{title} ({searchResults?.length})</h2>}
                </div>

                <div className="practice-dropdown-container">
                    <Select
                        loading={isLoading}
                        value={selectedDropdownValue}
                        style={{ width: 200 }}
                        onChange={handleChange}
                        fieldNames={{ label: 'practiceName', value: 'id' }}
                        options={medicalPractices}
                    />
                </div>
            </div>

            <div className="table-container">
                {!rerender && <Table
                    bordered
                    key="id"
                    loading={isLoading}
                    columns={usersColumns}
                    dataSource={searchResults}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 270px)', x: 100 }}
                />}

                {rerender && <Table loading />}
            </div>
        </div>
    );
}

export default Users;