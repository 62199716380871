import './style.scss';
import React, { useState } from 'react';
import { Button } from 'antd';
import { toast } from "react-toastify";
import { SyncOutlined } from '@ant-design/icons';

import ApiService from '@services/api.service';

function MailJet() {
    const [isUpdateContactsLoading, setIsUpdateContactsLoading] = useState(false);

    const updateMailJetContacts = () => {
        setIsUpdateContactsLoading(true);

        ApiService.updateMailJetContacts().then((res) => {
            setIsUpdateContactsLoading(false);
            toast.success("Contacts have been synced!", {
                position: "top-right",
            });
        }).catch((error) => {

            setIsUpdateContactsLoading(false);
            toast.error("Something went wrong!", {
                position: "top-right",
            });
        });
    }

    return (
        <div>
            <div className="section-header">
                <h2>Mailjet</h2>
                <Button loading={isUpdateContactsLoading} icon={<SyncOutlined rev={undefined} />} onClick={() => { updateMailJetContacts() }} type="primary" shape="round" size={'large'}>
                    Sync Contacts
                </Button>
            </div>
        </div>
    );
}

export default MailJet;